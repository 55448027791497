<template>
  <div class='payment-invoice-data-form' ref="paymentInvoiceForm">
    <error-toast :text="errorToast.text" :isVisible="errorToast.isVisible" />
    <section class="form-section">
      <h1 class="title">{{ $t(invoiceSection.sectionTitle) }}</h1>
      <div class="checkboxes-wrapper">
        <base-checkbox
          v-for="(checkbox, i) in invoiceSection.checkboxes" :key="i"
          :label="$t(checkbox.label)"
          :isChecked="checkbox.isChecked"
          @changed="handleCheckboxInput(i, ...arguments)"
        />
      </div>
      <div class="inputs-wrapper">
        <template v-for="(input, i) in invoiceSection.inputs" >
          <base-input
            v-if="isCompanyNameInputVisible(i) && !input.options && isTaxNumberInputVisible(input.key)"
            :labelText="$t(input.label)"
            :hasError="input.hasError"
            :defaultValue="input.value"
            :key="input.key"
            :type="input.type ? input.type : 'text'"
            @input="handleInputs(i, ...arguments)"
          />
          <base-selector
            v-else-if="input.options"
            :options="input.options"
            :defaultSelected="input.value"
            :key="input.key"
            :labelText="$t(input.label)"
            :hasError="input.hasError"
            @selected="handleCountrySelect"
          />
        </template>
      </div>
      <div class="buttons-row">
        <base-button
          :isLoading="isBtnLoading"
          :text="$t('pages.payment.tabs.invoiceData.buttons.submit.text')"
          @clicked="handleBtnClick"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import scroll from '@/components/shared/mixins/scroll';
import validations from '@/components/shared/mixins/validations';

import BaseInput from '@/components/shared/elements/inputs/BaseInput';
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';
import BaseCheckbox from '@/components/shared/elements/inputs/BaseCheckbox';
import BaseSelector from '@/components/shared/elements/inputs/BaseSelector';

export default {
  name: 'PaymentInvoiceDataForm',
  mixins: [scroll, validations],
  props: {},
  components: {
    BaseInput,
    BaseButton,
    BaseCheckbox,
    BaseSelector,
    'error-toast': () => import('@/components/shared/elements/toasts/ErrorToast'),
  },
  data: () => ({
    errorToast: {
      isVisible: false,
      text: undefined,
    },
    isBtnLoading: false,
    invoiceSection: {
      sectionTitle: 'pages.registration.form.sections.invoiceData.title',
      checkboxes: [
        {
          label: 'pages.registration.form.sections.invoiceData.checkboxes.personal',
          isChecked: true,
        },
        {
          label: 'pages.registration.form.sections.invoiceData.checkboxes.company',
          isChecked: false,
        },
      ],
      inputs: [
        {
          key: 'companyName',
          required: true,
          label: 'pages.registration.form.sections.invoiceData.labels.companyName',
          value: '',
          hasError: false,
        },
        {
          key: 'taxNumber',
          required: true,
          label: 'pages.registration.form.sections.invoiceData.labels.taxNumber',
          value: undefined,
          hasError: false,
        },
        {
          key: 'country',
          required: true,
          label: 'pages.registration.form.sections.invoiceData.labels.country',
          value: undefined,
          hasError: false,
          options: undefined,
        },
        {
          key: 'cityName',
          required: true,
          label: 'pages.registration.form.sections.invoiceData.labels.city',
          value: undefined,
          hasError: false,
        },
        {
          key: 'zipCode',
          required: true,
          label: 'pages.registration.form.sections.invoiceData.labels.zipCode',
          value: undefined,
          hasError: false,
        },
        {
          key: 'publicSpaceName',
          required: true,
          label: 'pages.registration.form.sections.invoiceData.labels.publicSpaceName',
          value: undefined,
          hasError: false,
        },
        {
          key: 'publicSpaceType',
          required: false,
          label: 'pages.registration.form.sections.invoiceData.labels.publicSpaceType',
          value: '',
          hasError: false,
        },
        {
          key: 'houseNumber',
          required: false,
          label: 'pages.registration.form.sections.invoiceData.labels.houseNumber',
          value: '',
          hasError: false,
        },
        {
          key: 'building',
          required: false,
          label: 'pages.registration.form.sections.invoiceData.labels.building',
          value: '',
          hasError: false,
        },
        {
          key: 'stairwayNumber',
          required: false,
          label: 'pages.registration.form.sections.invoiceData.labels.stairway',
          value: '',
          hasError: false,
        },
        {
          key: 'floor',
          required: false,
          label: 'pages.registration.form.sections.invoiceData.labels.floor',
          value: '',
          hasError: false,
        },
        {
          key: 'door',
          required: false,
          label: 'pages.registration.form.sections.invoiceData.labels.door',
          value: '',
          hasError: false,
        },
      ],
    },
    selectedSavedInvoiceIndex: -1,
    hasIncorrectValue: false,
  }),
  created() {
    if (this.getSelectedInvoiceDataIndex !== undefined) {
      this.selectedSavedInvoiceIndex = this.getSelectedInvoiceDataIndex;
      this.handleCheckboxInput(this.selectedSavedInvoiceIndex, true);
    } else {
      this.loadInvoiceData();
    }

    this.getCountries().then((resp) => {
      const countryIndex = this.invoiceSection.inputs.findIndex((item) => item.key === 'country');

      this.invoiceSection.inputs[countryIndex].options = resp.data.map((item) => item.name);
    });

    document.addEventListener('keyup', this.handleKeyPress);
  },
  computed: {
    ...mapGetters({
      loggedInUserProfile: 'users/getLoggedInUserProfile',
      getSelectedInvoiceDataIndex: 'invoiceData/getSelectedInvoiceDataIndex',
      getCurrentCart: 'cart/getCurrentCart',
    }),
  },
  watch: {
    selectedSavedInvoiceIndex(val) {
      this.saveSelectedInvoiceDataIndex(val);
    },
  },
  methods: {
    ...mapActions({
      updateInvoiceData: 'invoiceData/updateInvoiceData',
      addInvoiceData: 'invoiceData/addInvoiceData',
      saveSelectedInvoiceDataIndex: 'invoiceData/saveSelectedInvoiceDataIndex',
      getLoggedInUserProfile: 'users/getLoggedInUserProfile',
      attachInvoiceData: 'cart/attachInvoiceData',
      getCart: 'cart/getCart',
      getCountries: 'settings/getCountries',
    }),
    handleCheckboxInput(index, value) {
      if (value) {
        // eslint-disable-next-line no-param-reassign
        this.invoiceSection.checkboxes.forEach((item) => { item.isChecked = false; });
        this.invoiceSection.checkboxes[index].isChecked = value;

        // reset company name field
        this.invoiceSection.inputs.filter((item) => item.key === 'companyName')[0].value = '';

        this.loadInvoiceData();
      }
    },
    handleInputs(index, value) {
      this.invoiceSection.inputs[index].value = value;
    },
    isCompanyNameInputVisible(index) {
      if (index === 0 && this.invoiceSection.checkboxes[1].isChecked) {
        return true;
      }
      if (index === 0) {
        return false;
      }

      return true;
    },
    isTaxNumberInputVisible(key) {
      if (key === 'taxNumber' && this.invoiceSection.checkboxes[1].isChecked) {
        return true;
      }
      if (key === 'taxNumber') {
        return false;
      }

      return true;
    },
    emptyInputValues() {
      for (let i = 0; i < this.invoiceSection.inputs.length; i += 1) {
        this.invoiceSection.inputs[i].value = '';
      }
    },
    loadInvoiceData() {
      if (this.loggedInUserProfile !== undefined && this.loggedInUserProfile.invoiceData.length > 0) {
        if (this.invoiceSection.checkboxes[0].isChecked) { // personal
          const personalInvoiceDataIndex = this.loggedInUserProfile.invoiceData.findIndex((item) => item.isCompany === false);
          this.selectedSavedInvoiceIndex = personalInvoiceDataIndex;
          if (personalInvoiceDataIndex > -1) {
            this.initInputValuesFromStore(this.loggedInUserProfile.invoiceData[personalInvoiceDataIndex]);
          } else {
            this.emptyInputValues();
            this.selectedSavedInvoiceIndex = -1;
          }
        } else { // company
          const personalInvoiceDataIndex = this.loggedInUserProfile.invoiceData.findIndex((item) => item.isCompany === true);
          this.selectedSavedInvoiceIndex = personalInvoiceDataIndex;
          if (personalInvoiceDataIndex > -1) {
            this.initInputValuesFromStore(this.loggedInUserProfile.invoiceData[personalInvoiceDataIndex]);
          } else {
            this.emptyInputValues();
            this.selectedSavedInvoiceIndex = -1;
          }
        }
      }
    },
    initInputValuesFromStore(invoiceData) {
      Object.keys(invoiceData).forEach((key) => {
        const inputIndex = this.invoiceSection.inputs.findIndex((item) => item.key === key);
        if (inputIndex > -1) {
          this.invoiceSection.inputs[inputIndex].value = invoiceData[key];
        }
      });
    },
    checkValueChange() {
      const isCompanySelected = this.invoiceSection.checkboxes[1].isChecked; // is false personal is selected
      if (this.loggedInUserProfile.invoiceData.length > 0) {
        const savedInvoiceData = this.loggedInUserProfile.invoiceData.find((item) => item.isCompany === isCompanySelected);

        if (savedInvoiceData !== undefined) {
          for (let i = 0; i < this.invoiceSection.inputs.length; i += 1) {
            // eslint-disable-next-line prefer-destructuring
            const key = this.invoiceSection.inputs[i].key;
            if (savedInvoiceData[key] === undefined && (this.invoiceSection.inputs[i].value !== undefined && this.invoiceSection.inputs[i].value.length > 0)) {
              return true;
            } if (savedInvoiceData[key] !== undefined && savedInvoiceData[key] !== this.invoiceSection.inputs[i].value) {
              return true;
            }
          }
        }
      }

      return false;
    },
    handleCountrySelect(payload) {
      const countryIndex = this.invoiceSection.inputs.findIndex((item) => item.key === 'country');

      this.invoiceSection.inputs[countryIndex].value = payload;
    },
    parseRequestObject() {
      return {
        isCompany: this.invoiceSection.checkboxes[1].isChecked,
        companyName: this.invoiceSection.inputs.filter((item) => item.key === 'companyName')[0].value,
        taxNumber: this.invoiceSection.inputs.filter((item) => item.key === 'taxNumber')[0].value,
        country: this.invoiceSection.inputs.filter((item) => item.key === 'country')[0].value,
        cityName: this.invoiceSection.inputs.filter((item) => item.key === 'cityName')[0].value,
        zipCode: this.invoiceSection.inputs.filter((item) => item.key === 'zipCode')[0].value,
        publicSpaceName: this.invoiceSection.inputs.filter((item) => item.key === 'publicSpaceName')[0].value,
        publicSpaceType: this.invoiceSection.inputs.filter((item) => item.key === 'publicSpaceType')[0].value,
        houseNumber: this.invoiceSection.inputs.filter((item) => item.key === 'houseNumber')[0].value,
        building: this.invoiceSection.inputs.filter((item) => item.key === 'building')[0].value,
        stairwayNumber: this.invoiceSection.inputs.filter((item) => item.key === 'stairwayNumber')[0].value,
        floor: this.invoiceSection.inputs.filter((item) => item.key === 'floor')[0].value,
        door: this.invoiceSection.inputs.filter((item) => item.key === 'door')[0].value,
      };
    },
    validateTaxNumberLocale() {
      const taxNumberField = this.invoiceSection.inputs.filter((item) => item.key === 'taxNumber')[0];
      const validationResult = this.validateTaxNumber(taxNumberField.value);

      if (!validationResult) {
        taxNumberField.hasError = true;
        this.errorToast.isVisible = true;
        this.errorToast.text = this.$t('general.errors.taxNumber');
      }

      return validationResult;
    },
    validateForm() {
      this.hasIncorrectValue = false;

      this.invoiceSection.inputs.forEach((input, i) => {
        if ((i === 0 || input.key === 'taxNumber') && this.invoiceSection.checkboxes[1].isChecked) {
          if (input.value === undefined || input.value.length === 0) {
            this.invoiceSection.inputs[i].hasError = true;
          } else {
            this.invoiceSection.inputs[i].hasError = false;
          }
        } else if ((i === 0 || input.key === 'taxNumber') && !this.invoiceSection.checkboxes[0].isChecked) {
          this.invoiceSection.inputs[i].hasError = false;
        }

        if ((i !== 0 && input.key !== 'taxNumber') && input.required) {
          if (input.value === undefined || input.value.length === 0) {
            this.invoiceSection.inputs[i].hasError = true;
          } else if (input.key === 'zipCode') {
            this.invoiceSection.inputs[i].hasError = !this.validateZipCode(input.value);
            this.hasIncorrectValue = this.hasIncorrectValue.invoiceSection ? true : this.invoiceSection.inputs[i].hasError;
          } else if (input.key === 'cityName' || input.key === 'publicSpaceName') {
            this.invoiceSection.inputs[i].hasError = !this.validateStringInput(input.value);
            this.hasIncorrectValue = this.hasIncorrectValue ? true : this.invoiceSection.inputs[i].hasError;
          } else {
            this.invoiceSection.inputs[i].hasError = false;
          }
        }
      });

      const invoiceSectionHasError = this.invoiceSection.inputs.filter((input) => input.hasError).length > 0;

      if (invoiceSectionHasError) {
        this.errorToast.isVisible = true;
        this.errorToast.text = this.hasIncorrectValue
          ? this.$t('general.errors.incorrectValues') : this.$t('general.errors.missingFields');
      } else if (this.invoiceSection.checkboxes[1].isChecked) {
        this.validateTaxNumberLocale();
      } else {
        this.errorToast.isVisible = false;
        this.errorToast.text = undefined;
      }

      return this.invoiceSection.inputs.filter((input) => input.hasError).length > 0;
    },
    handleBtnClick() {
      const isFormValid = !this.validateForm();

      if (isFormValid) {
        const requestObj = this.parseRequestObject();
        const isUpdateNeed = this.checkValueChange();
        const promises = [];

        if (isUpdateNeed) {
          const invoiceId = this.loggedInUserProfile.invoiceData[this.selectedSavedInvoiceIndex]._id;

          this.isBtnLoading = true;

          promises.push(
            this.updateInvoiceData({
              invoiceId,
              invoiceData: requestObj,
            }).then(() => this.getLoggedInUserProfile()),
          );

          promises.push(
            this.attachInvoiceData({
              cartId: this.getCurrentCart._id,
              invoiceData: {
                invoiceData: { ...requestObj },
              },
            }).then(() => this.getCart(this.getCurrentCart._id)),
          );

          Promise.all(promises).then(() => {
            this.isBtnLoading = false;
            this.$emit('stepFinishedSuccessfuly');
          }).catch((error) => {
            this.isBtnLoading = false;
            this.errorToast.isVisible = true;
            this.errorToast.text = error.data.message;

            this.scrollToToast('paymentInvoiceForm');
          });
        } else if (this.selectedSavedInvoiceIndex === -1) {
          this.isBtnLoading = true;

          promises.push(
            this.addInvoiceData(requestObj).then(() => this.getLoggedInUserProfile()),
          );

          promises.push(
            this.attachInvoiceData({
              cartId: this.getCurrentCart._id,
              invoiceData: {
                invoiceData: { ...requestObj },
              },
            }).then(() => this.getCart(this.getCurrentCart._id)),
          );

          Promise.all(promises).then(() => {
            this.isBtnLoading = false;
            this.$emit('stepFinishedSuccessfuly');
          }).catch((error) => {
            this.isBtnLoading = false;
            this.errorToast.isVisible = true;
            this.errorToast.text = error.data.message;

            this.scrollToToast('paymentInvoiceForm');
          });
        } else {
          this.isBtnLoading = true;

          this.attachInvoiceData({
            cartId: this.getCurrentCart._id,
            invoiceData: {
              invoiceData: { ...requestObj },
            },
          }).then(() => {
            this.getCart(this.getCurrentCart._id).then(() => {
              this.isBtnLoading = false;
              this.$emit('stepFinishedSuccessfuly');
            });
          }).catch((error) => {
            this.isBtnLoading = false;
            this.errorToast.isVisible = true;
            this.errorToast.text = error.data.message;

            this.scrollToToast('paymentInvoiceForm');
          });
        }
      } else {
        this.scrollToToast('paymentInvoiceForm');
      }
    },
    handleKeyPress(e) {
      if (e.key === 'Enter') {
        this.handleBtnClick();
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyPress);
  },
};
</script>

<style lang='scss' scoped>
.error-toast {
  margin: 0 0 20px;
}

.form-section {
  margin: 0 auto 17px;

  .title {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    color: $globalFontColor;
    margin: 0 0 15px;
  }
}

.inputs-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;

  @media screen and (max-width: 477.98px) {
    grid-template-columns: 1fr;
    column-gap: 0;
  }
}

.checkboxes-wrapper {
  display: flex;
  margin: 0 0 10px 5px;

  .base-checkbox {
    &:first-child {
      margin: 0 10px 0 0;
    }
  }
}

.buttons-row {
  margin: 30px 0 0;

  .button-base {
    min-width: 200px;
  }
}
</style>
